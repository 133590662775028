<template>
  <cms-recommendation-carousel
    :currency="currency[locale]"
    :loading
    :on-product-click="handleProductClick"
    :products="recommendedProducts"
    :quickshop
    :title="content.title"
    :variant="content.variant"
  />
</template>

<script lang="ts" setup>
import type { BloomreachRecommendations } from '#types/components/cms/bloomreach-recommendations'
import { extractBloomReachProduct } from '#core/utils/p13n'

const props = defineProps<{ content: BloomreachRecommendations }>()

const { currency } = useRuntimeConfig().public
const locale = useLocale()
const { $gtm } = useNuxtApp()
const route = useRoute()
const { enableQuickShop } = useAppConfig().components.cms.productRecommendations
const { handleBloomreachProductClick } = useMonetate()

const quickshop = props.content.quickShopVisibility ? props.content.quickShopVisibility === 'visible' : enableQuickShop

const loading = ref(true)
const recommendedProducts = ref<any>([])

const getRecommendedProducts = (products = []) =>
  products.map((product) => extractBloomReachProduct(product, props.content.showRatings))

const loadProducts = () => {
  const products = window.vfbr?.[props.content.bloomreachIndex || 0] || []
  recommendedProducts.value = getRecommendedProducts(products)
  loading.value = false
}

const handleProductClick = (card) => {
  handleBloomreachProductClick(card, props.content.title, route.path)
}

onMounted(() => {
  loadProducts()
  if (recommendedProducts.value) {
    $gtm.push('product.onProductRecImpressions', recommendedProducts.value, {
      currency: currency[locale],
      list: 'Product Recommendation Carousel',
      title: props.content.title
    })
  }

  window.addEventListener('recalculateBloomreachVfa', loadProducts)
})

onBeforeUnmount(() => {
  window.removeEventListener('recalculateBloomreachVfa', loadProducts)
})
</script>
